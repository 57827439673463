.projectSection {
    width: 90%;
    margin: 4rem 1.5rem;

    @media screen and (min-width: 800px) {
        width: 90%;
        display: grid;
        place-items: center;
        margin: 6rem auto 2rem;
    }

    .head {
        margin-bottom: 3rem;

        .title {
            margin-top: 0;
        }

        .text {
            text-align: center;

            @media screen and (min-width: 768px) {
                width: 35rem;
            }
        }
    }

    .subhead {
        margin-bottom: 2rem;

        @media screen and (min-width: 768px) {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        .text {
            text-align: center;

            @media screen and (min-width: 768px) {
                width: 35rem;
            }
        }
    }

    .projectList {
        display: block;

        .projectContainer {
            display: block;
            padding: 2rem 0;
            border-bottom: 1px solid hsla(210, 100%, 95%, 0.122);

            .contentContainer {
                display: grid;
                grid-template-areas: "image" "heading" "text";
                grid-template-columns: auto;
                padding: 0rem;
                gap: 1rem;
                cursor: pointer;
                
                @media screen and (min-width: 900px) {
                    display: grid;
                    grid-template-areas: "image heading space text";
                    grid-template-columns: 200px min-content 1fr 40%;
                    grid-template-rows: auto 1fr;
                    align-items: center;
                    gap: 1.5rem;
                }

                .projectImage img {
                    grid-area: image;
                    width: 100%;
                }

                .contentTitle {
                    grid-area: heading;
                    color: var(--white);
                    font-family: var(--font-head);
                    font-size: var(--twentyFour);
                    font-weight: 400;
                    white-space: nowrap;
                    display: flex;
                    align-items: flex-end;
                    
                    
                    .arrowContainer {
                        margin-left: 1rem;
                        
                        svg {
                            width: 2rem;
                            height: 1.5rem;
                            display: flex;

                            @media screen and (min-width: 1000px){
                                width: 2.5rem;
                                height: 2rem;
                            }
                        }
                    }

                    @media screen and (min-width: 1000px){
                        font-size: var(--thirtyTwo);
                        justify-content: space-between;
                    }
                }

                .contentText {
                    display: block;
                    grid-area: text;

                    .lang {
                        color: hsl(175 80% 82%);
                        font-family: var(--font-text);
                        font-size: var(--fourteen);
                        font-weight: 400;
                        line-height: 1.5em;
                    }
                    .text {
                        color: var(--white);
                        font-family: var(--font-text);
                        font-size: var(--twenty);
                        font-weight: 400;
                        line-height: 1.5em;
                        margin-top: 0em;
                    }
                }
            }
        }
    }
}
