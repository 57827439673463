@use "sass:string";
$headFont: "Satoshi";
$textFont: "Karla", sans-serif;

/*------------ [Font] -----------*/
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700&display=swap");

/*------------ [Root]-----------*/
$white: #ffffff;
$text: #555555;
$purple: #d1c3fe;
$litePurple: #eadaf0;
$pink: #ebcce9;
$hotPink: #893583;
$litePink: #fffbff;
$black: #050205;
$grey: #b8b8b8;
$greyPro: #dedddd;
$assets: rgba(137, 53, 131, 0.3);

:root {
    /* Colors */
    --white: #{$white};
    --text: #{$text};
    --purple: #{$purple};
    --litePurple: #{$litePurple};
    --pink: #{$pink};
    --hotPink: #{$hotPink};
    --litePink: #{$litePink};
    --black: #{$black};
    --grey: #{$grey};
    --greyPro: #{$greyPro};
    --assets: #{$assets};

    /* FontStyle*/
    --font-head: #{$headFont};
    --font-text: #{$textFont};

    /* Font Sizes */
    --fourty: 2rem;
    --thirtyEight: 1.95rem;
    --thirtyTwo: 1.75rem;
    --twentyFour: 1.25rem;
    --twenty: 1.15rem;
    --eighteen: 1.0625rem;
    --sixteen: 1rem;
    --fourteen: 0.88rem;
    --twelve: 0.75rem;
    --ten: 0.625rem;
}

@media screen and (min-width: 900px) {
    :root {
        --fourty: 2.5rem;
        --thirtyEight: 2.375rem;
        --thirtyTwo: 2rem;
        --thirty: 1.875rem;
        --twentyEight: 1.75rem;
        --twentyFour: 1.5rem;
        --twenty: 1.25rem;
        --eighteen: 1.125rem;
        --sixteen: 1rem;
        --fourteen: 0.88rem;
        --twelve: 0.75rem;
        --ten: 0.625rem;
    }
}

/*========================================*/
/*------------ [_Reset_Styles] -----------*/
/*========================================*/
*,
::before,
::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Satoshi';
    src: url('./assets/fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'),
         url('./assets/fonts/Satoshi/Satoshi-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  
  body {
    font-family: 'Satoshi Variable', sans-serif;
  }
  
/*========================================*/
/*--------------- [_Utility] -------------*/
/*========================================*/
body {
    max-width: 100vw;
    height: 100vh;
    background: var(--black);
    color: var(--white);
    font-family: var(--font-text);
    letter-spacing: -0.5px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}

h2,
h3,
h4,
h5,
h6 {
    color: var(--black);
    font-family: var(--font-head);
}

p {
    font-family: var(--font-text);
    font-size: var(--sixteen);
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--white);
}

h1 {
    font-size: var(--eighty);
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    color: var(--white);
    letter-spacing: 0px;
}

h2 {
    font-size: var(--sixty);
    line-height: 2rem;
    font-weight: 700;
}

h3 {
    font-size: var(--fiftyEight);
    line-height: 2rem;
    font-weight: 600;
}

h4 {
    font-size: var(--fourtyEight);
    line-height: 2.5rem;
    font-weight: 600;
}

h5 {
    font-size: var(--twentyFour);
    font-weight: 500;
    line-height: 1.5rem;
}

h6 {
    font-size: var(--eighteen);
    line-height: 1.2rem;
    font-weight: 600;
}

a {
    text-decoration: none;
    color: inherit;
    font-family: var(--font-text);
}

img {
    border: none;
    max-width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

abbr {
    text-decoration: none;
}

ul li {
    list-style: none;
}

/*==================[Button]======================*/
button {
    font-family: var(--font-text);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        transform: scale(0.9, 0.9);
    }
}

.btnWrap{
    display: flex;
}

.btnPrimary {
    background: var(--litePink);
    height: 3.5rem;
    color: var(--black);
    border: 1px solid var(--hotPink);
    padding: 0.75rem 1rem;
    font-size: var(--twenty);
    font-weight: 500;
    border-radius: 28px;
    transition: all 0.3s ease;
    outline: none;
}

.btnSecondary {
    background: var(--black);
    height: 4rem;
    color: var(--white);
    border: 1px solid var(--hotPink);
    padding: 0.75rem 1rem;
    font-size: var(--twenty);
    font-weight: 500;
    border-radius: 12px;
    transition: all 0.3s ease;
    outline: none;
}

/*--------------- [Body] -------------*/
.mainWrapper {
    display: grid;
    height: 100%;
    grid-template-columns: 100%;
    overflow-x: hidden;
}

.homeWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    
    background-image: url(assets/images/background.webp);
    background-size: cover;
    background-position: center;
}

.mainWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.wrapper {
    height: 100vh;
    overflow: scroll;
    display: grid;
    grid-template-columns: 100%;
}

.title {
    font-family: var(--font-text);
    font-size: var(--thirtyEight);
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: var(--white);
    margin-top: 1rem;
}

.subtitle {
    font-size: var(--twentyFour);
    font-weight: 500;
    line-height: 2.5rem;
}

.text {
    margin-top: 1rem;
    font-size: var(--twenty);
    line-height: 1.5rem;
    color: var(--white);
}

.boldText {
    font-size: var(--twenty);
    font-weight: 600;
    line-height: 1.5rem;
}

// span
.purpleColor{
    color: var(--purple);
}