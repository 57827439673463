.desktopHeader {
        display: block;
        padding-top: 3.5rem;
        width: 95%;
        margin: 0 auto;
    
        @media screen and (min-width: 900px) {
            width: 90%;
        }
    .navItems {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logoName {
            font-family: var(--font-text);
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.5rem;
            color: var(--white);

            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 0.75rem;
            }
        }

        .activeLogo {
            font-family: var(--font-text);
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.5rem;
            color: var(--litePink);

            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 0.75rem;
            }
        }

        .navList {
            display: flex;

            .navItem {
                margin-right: 1rem;

                .navLink {
                    font-family: var(--font-text);
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.5rem;
                    color: var(--white);
                }

                .activeLink {
                    font-family: var(--font-text);
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 1.5rem;
                    color: var(--litePink);
                }
            }
        }
    }
}

.mobileHeader {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.5rem;
    width: 85%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
        display: none;
    }

    .navItems {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border: 1.5px solid var(--white);
        border-radius: 2rem;
    }

    .navList {
        display: none;
        width: 100vw;
        position: absolute;
        z-index: 1;
        top: 5rem;
        right: 0;
        background: var(--pink);
        border-radius: 2px;
        animation: animate 0.3s;

        .navItem {
            display: grid;
            place-items: center;
            width: 100%;

            .navLink {
                font-size: var(--eighteen);
                font-weight: 600;
                color: var(--black);
                width: 100%;
                display: grid;
                place-items: center;
                padding: 1rem;
            }

            .activeLink {
                font-size: var(--eighteen);
                font-weight: 600;
                background-color: var(--hotPink);
                color: var(--white);
                width: 100%;
                display: grid;
                place-items: center;
                padding: 1rem;
            }
        }
    }

    .show {
        display: grid;
        grid-template-columns: 100%;
        place-items: center;
    }

    @keyframes animate {
        from {
            transform: scale(0.5);
        }
        to {
            transform: scale(1);
        }
    }
}
