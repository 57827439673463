.workSection {
    width: 90%;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        margin: 2rem  auto;
    }

    .head {
        margin-bottom: 3rem;

        .title {
            margin-top: 0;
            text-align: left;
        }

        .text {
            text-align: left;

        }
        @media screen and (min-width: 768px) {
            width: 45%;
        }
    }

    .workList {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 800px) {
        width: 55%;
        }

        .workWrap {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid;

            @media screen and (min-width: 800px) {
                margin-bottom: 2rem;
            }

            .projectDescription {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                background: rgb(0 0 0 / 79%);

                .projectName {
                    text-transform: uppercase;
                    font-size: var(--eighteen);
                    font-weight: 600;
                }

                .projectLang {
                    font-size: var(--sixteen);
                    color: var(--purple);
                    margin-bottom: 0.25rem;
                    // text-transform: uppercase;
                }

                .projectDetails {
                    font-size: var(--sixteen);
                    margin-bottom: 1rem;
                }

                .btnPrimary {
                    width: 8rem;
                    height: 3rem;
                    display: grid;
                    place-items: center;
                    text-align: center;
                    border-radius: 16px;
                    font-size: var(--eighteen);
                    transition: all 0.3s;

                    &:hover {
                        transform: scale(0.9, 0.9);
                    }
                }

                .btnSecondary {
                    width: 10rem;
                    height: 3rem;
                    display: grid;
                    place-items: center;
                    text-align: center;
                    border-radius: 16px;
                    font-size: var(--eighteen);
                    margin-left: 1rem;
                    transition: all 0.3s;

                    &:hover {
                        transform: scale(0.9, 0.9);
                    }
                }

                @media screen and (min-width: 800px) {
                    height: 100%;
                }
            }
        }
    }
}
