.aboutSection{
    display: grid;
    place-items: center;
    margin: 5rem auto 0;
    justify-content: center;
    text-align: center;
    width: 90%;

    @media screen and (min-width: 768px){
        width: 34.5rem;
    }

    .aboutDescription{
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .text{
            margin-top: 1rem;
            font-size: var(--eighteen);
            line-height: 2rem;
            text-align: left;
            
        }
    }
}