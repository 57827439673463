.aboutHero{
    display: flex;
    flex-direction: column;
    margin: 4rem auto 2rem;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 90%;
    max-width: 60rem;
    
    @media screen and (min-height: 800px) {
        margin: 4rem auto 3rem;
    }
    

    .profilePicture{
        width: 12rem;
        height: 12rem;
        margin-top: 1rem;
        background: var(--purple);
        border-radius: 50%;

        @media screen and (min-width: 768px){
            width: 15rem;
            height: 15rem;
        }
        
        .img{
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .profileDescription{
        display: flex;
        flex-direction: column;

        .title{
            font-family: var(--font-head);
            font-size: var(--thirtyTwo);
            font-weight: 500;
            line-height: 2rem;
            text-align: center;
            color: var(--white);
            margin-top: 1.5rem;
            max-width: 35rem;
            align-self: center;

            .wave {
                animation: wave-animation 2.5s infinite;
                display: inline-block;
                transform-origin: 70% 70%;
                animation-timing-function: cubic-bezier(0.36, 0.45, 0.63, 0.53);
              }
              
              @keyframes wave-animation {
                0% {
                  transform: rotate(15deg);
                }
                50% {
                  transform: rotate(70deg);
                }
                75% {
                  transform: rotate(-15deg);
                }
                100% {
                  transform: rotate(15deg);
                }
              }

            .colorText{
                color: var(--purple);
            }
        }
    }
    .aboutContent{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        
        .grid{
            display: flex;
            flex-direction: column;
            margin-top: 4rem;

            @media screen and (min-width: 500px) {
                display: grid;
                gap: 2rem;
                grid-template-columns: 20% 75%;
            }

            .column{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                text-align: left;

                .textTitle{
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: .075em;
                    text-transform: uppercase;
                    font-family: var(--font-head);
                }

                .text{
                    margin-top: .5rem;
                    font-size: var(--eighteen);
                    line-height: 1.5rem;
                    color: var(--white);
                }

                .grid{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin-top: 0rem;
        
                    @media screen and (min-width: 500px) {
                        display: grid;
                        gap: 2rem;
                        grid-template-columns: 1fr 1fr;
                    }

                    .column{
                        .labels{
                            text-align: left;
                            margin-bottom: 1rem;

                            .labelTitle{
                                color: var(--purple);
                                line-height: 2.5rem;
                                font-weight: 600;
                                font-size: var(--eighteen);
                            }

                            .labelValue{
                                line-height: 1.5rem;
                            }
                        }
                    }
                }
            }
        }

    }
    .btnPrimary{
        width: fit-content;
        height: 3rem;
        margin-top: 1.5rem;
        margin-bottom: .75rem;
        background: transparent;
        border: 2px solid var(--pink);
        color: var(--white);
        display: flex;
        align-items: center;

        &:hover{
            transform: scale(.95, 1);
        }

    .downloadBtn{
        margin-right: 1rem;
    }
    }
}